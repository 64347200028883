.main {
  height: 100%;
  margin: 0px;
  padding: 25px;
  overflow-y: auto;
  width: 100%;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}

.send {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
  margin-top: -40px;
}

.send:hover {
  cursor: pointer;
  transform: scale(1.04);
}
