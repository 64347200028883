.paper {
  padding: 10px;
  margin-bottom: 20px;
}
.nuggetTitle {
  padding: 5px;
}
.nuggetObject {
  padding: 10px;
  margin: 12px 0 0 5px;
  padding-bottom: 0px;
  display: flex;
  width: 88%;
  text-align: left;
  /* font-family: 'MyWebFont1'; */
  color: #484848;
}
.title {
  padding: 2px 0px 0px 15px;
  cursor: pointer;
}
.icons {
  border-radius: 5px;
}

.icons:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.objImg {
  margin-top: 13px;
}
.addbuttongreen {
  background: rgb(67, 161, 67);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.addbutton {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  font-weight: bold;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.addbutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.addbuttongreen:hover {
  cursor: pointer;
  transform: scale(1.05);
}
