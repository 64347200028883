.mainpopup{
    width: 40%;
    height: fit-content;
    background: white;
    padding: 20px;
    border-radius: 5px;
    overflow-y: scroll;
}

.heading{
  font-size: 16px;
  text-align: center;
}

.slotsholder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.userselectholder{
    padding-bottom: 10px;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 30px;
}

.submit{
background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.cancel{
background: transparent;
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: var(--color-button-background);
  font-weight: 300px;
  border-radius: 5px;
  border: 1px solid var(--color-button-background);
  margin: 0px;
}

.submit:hover{
    cursor: pointer;
  transform: scale(1.05);
}

.cancel:hover{
    cursor: pointer;
  transform: scale(1.05);
}