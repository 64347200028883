.main {
    height: 100%;
    margin: 0px;
    padding: 25px;
    overflow-y: auto;
    width: 100%;
  }

  .maincontainer {
    height: 95%;
    width: 1100px;
    padding: 20px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .closeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .closeicon:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  .heading {
    font-size: 16px;
    text-align: left;
    margin: 0px;
    padding: 0px;
    font-weight: bold;
  }
  .formfieldsholder {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding-top: 10px;
    margin: 0 8%;
  }
  .leftside {
    width: 48%;
  }
  .rightside {
    width: 48%;
  }
  .inputblock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    padding: 5px 0px;
  }
  .labelholder {
    width: 75%;
    text-align: left;
  }
  .labelholderBT {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputholder {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
  }
  .holder {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: center;
  }
  .label {
    font-size: 12px;
    font-weight: 400;
  }
  .inputfield {
    width: 100%;
    padding: 5px;
    height: 25px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-size: 12px;
  }
  .textfield {
    width: 100%;
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-size: 12px;
    resize: none;
  }
  .smallfield {
    width: fit-content;
    padding: 5px;
    height: 25px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-size: 12px;
  }
  .fileupload {
    height: 100px;
    width: 100%;
    border: 1px dotted black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
  }
  .timeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }
  .endtimeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }
  .checkholder {
    width: 60%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .paywithindate {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
  .paywithholder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .bottomfields {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .buttonholder {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 0px;
  }
  .add {
    background: var(--color-button-background);
    padding: 5px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    margin: 0px;
  }
  .add:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  .cancel {
    background: white;
    padding: 5px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: var(--color-button-background);
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid var(--color-button-background);
    margin: 0px;
  }
  .cancel:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  .textarea {
    width: 100%;
    border-radius: 10px;
    resize: none;
  }
  .span {
    font-size: 12px;
    margin-top: -10px;
    padding-left: 5px;
  }

  
  .cancelicon {
    width: 20px;
    height: 20px;
  }
  .fileEllipsis {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  