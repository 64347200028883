.main{
    width: 30%;
    height: fit-content;
    background: white;
    border: none;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.heading {
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
    padding-bottom: 20px;
  }

.buttonholders{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.delete{
font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
}

.delete:hover{
    cursor: pointer;
  transform: scale(1.04);
}

.cancel{
    font-size: 14px;
    font-weight: bold;
    color: var(--color-button-background);
    padding: 5px 20px;
    border: none;
    background: white;
    border-radius: 5px;
    border: 1px solid var(--color-button-background);
}

.cancel:hover{
    cursor: pointer;
    transform: scale(1.04); 
}