.paper {
  padding: 10px;
  margin-bottom: 20px;
}
.nuggetTitle {
  padding: 5px;
}
.nuggetObject {
  padding: 10px;
  margin: 12px 0 0 5px;
  padding-bottom: 0px;
  display: flex;
  width: 88%;
  text-align: left;
  /* font-family: 'MyWebFont1'; */
  color: #484848;
}
.title {
  padding: 2px 0px 0px 15px;
  cursor: pointer;
}
