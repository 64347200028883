.header {
  padding: 12px;
  width: 100%;

  height: 45px;
  overflow: hidden;
  background: #285780;
  color: white;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.uiObject {
  overflow: hidden;
  width: 40%;
  //border: 1px solid black;
  border-radius: 2px;
  color: black;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 62px;
  padding: 14px;
}
.list {
  display: flex;
}

.nuggetBlock {
  margin-bottom: 30px;
}
.image {
  padding: 30px;
}

.header{
  font-size: 16px;
  font-weight: 400;
}


.main{
  background: white;
  padding: 20px;
  border-radius: 5px;
  height: 60%;
  width: 70%;
  overflow-y: scroll;
}

.topbar{
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 20px;
}

.closeicon:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.desingBlock{
  display: flex;
  align-items: center;
  justify-content: space-between;
}