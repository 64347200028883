.maincontainer {
  height: fit-content;
  width: 80%;
  padding: 20px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  overflow-y: scroll;
  max-height: 70%;
}

.closeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeicon:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.heading {
  font-size: 16px;
  text-align: left;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}

.formfieldsholder {
  width: 100%;
  padding-top: 10px;
}

.leftside {
  width: 100%;
}

.inputblock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 5px 0px;
}

.labelholder {
  width: 30%;
  text-align: right;
}

.inputholder {
  width: 55%;
  text-align: left;
}

.holder {
  width: 55%;
  text-align: left;
}

.label {
  font-size: 12px;
  font-weight: 400;
}

.inputfield {
  width: 100%;
  padding: 5px;
  height: 25px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 12px;
}

.textfield {
  width: 100%;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 12px;
  resize: none;
}

.smallfield {
  width: fit-content;
  padding: 5px;
  height: 25px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 12px;
}

.fileupload {
  height: 100px;
  width: 100%;
  border: 1px dotted black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.timeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.endtimeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.checkholder {
  width: 60%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.paywithindate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.paywithholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.bottomfields {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0px;
}

.add {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.add:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.cancel {
  background: white;
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: var(--color-button-background);
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid var(--color-button-background);
  margin: 0px;
}

.cancel:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.textarea {
  width: 100%;
  border-radius: 10px;
  resize: none;
}

.span {
  font-size: 12px;
  margin-top: -10px;
  padding-left: 5px;
}

.speakerlists {
  margin-left: 37%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.speakertag {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: 20px;
  padding: 2px;
  background: whitesmoke;
}

.speakername {
  font-size: 10px;
  font-weight: 400;
}

.cancelicon {
  width: 20px;
  height: 20px;
}
.rows {
  display: flex;
  gap: 40px;
  padding: 50px;
}
.objecttype {
  width: 175px;
  padding: 10px;
  border: 1px solid lightgrey;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 15px 40px 15px !important;
}
.outer {
  margin: 8px 40px;
  overflow: hidden;

  padding: 0 0 10px 0;
  display: flex;
  gap: 50px;
  /*  display: flex;
    justify-content: center; */
}
.lable {
  padding: 6px 0 0 0;
  float: left;
  width: 160px;
  color: #484848;
  text-align: left;
  font-size: 14px;
}
.value {
  padding: 6px 0 0 0;
  float: left;
  width: 160px;
  font-weight: 500;
  color: #484848;
  text-align: left;
  font-size: 14px;
}
.classtag {
  padding: 3px 10px 5px 10px;
  border-radius: 4px;
  //font-family: MyWebFont;
  border: 1px solid black;
  font-size: 14px;
  margin: 5px 5px 1px 0px;
  height: 28px;
  background-color: white !important;
  max-width: 200px;
  min-width: 100px;
  display: inline-block;
}
.classtagCk {
  margin: 4px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid gainsboro;
  overflow: auto;
  float: left;
  min-width: 130px;
  width: auto;
  overflow: hidden;
  color: #494848;
  font-size: 14px;
}
.options {
  margin: 8px 40px;
  overflow: hidden;

  padding: 0 0 0 0;
  display: flex;
  gap: 50px;
}
.speakertag {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: 20px;
  padding: 2px;
  background: whitesmoke;
}

.speakername {
  font-size: 10px;
  font-weight: 400;
}
