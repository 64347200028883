.main{
    background-color: "white";
    border-radius: 5px;
    height: 80%;
    overflow-y: scroll;
    
}

.closeholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}

.closeicon {
    cursor: pointer;
    transform: scale(1.04);
}

.closebutton{
    background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}