.mainpopup {
  height: 80%;
  width: 90%;
  overflow-y: scroll;
  padding: 20px;
  border: none;
  border-radius: 5px;
  background: white;
}

.closeholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.close:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.heading {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.tableholder {
  padding-top: 20px;
}

.addbutton {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.addbutton:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: var(--color-button-background);
  color: white;
}

.addbuttondisabled {
  background: grey;
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
  cursor: not-allowed;
}

//save button
.savebutton {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.savebutton:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: var(--color-button-background);
  color: white;
}

.savebuttondisabled {
  background: grey;
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
  cursor: not-allowed;
}

