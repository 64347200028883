.main{
    height: 40%;
    width: 60%;
    background: white;
    border-radius: 5px;
    padding: 20px;
    overflow-y: scroll;
}

.closeholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.close:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.searchbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.searchholder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
}

.searchinput{
    border: none;
    height: 30px;
    font-size: 14px;
    width: 200px;
    outline: none;
}

.clearbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    height: 30px;
    gap: 10px;
    width: fit-content;
    border: none;
    border-radius: 5px;
    background: var(--color-button-background);
}

.clearbar:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.clearheading{
    font-size: 14px;
    color: white;
}

.tenantlist{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    column-gap: 50px;
    padding-top: 20px;
}

.tenantnames{
    font-size: 14px;
    font-weight: 300;
}

.tenantnames:hover{
    cursor: pointer;
}

