.outer {
  margin: 8px 40px;
  overflow: hidden;
  border-bottom: 1px solid #a6a6a6;
  padding: 0 0 10px 0;
  display: flex;
  gap: 50px;
  /*  display: flex;
  justify-content: center; */
}
.lable {
  padding: 6px 0 0 0;
  float: left;
  width: 160px;
  color: #484848;
  text-align: left;
  font-size: 14px;
}
.value {
  padding: 6px 0 0 0;
  float: left;
  width: 160px;
  font-weight: 500;
  color: #484848;
  text-align: left;
  font-size: 14px;
}
.classtag {
  padding: 3px 10px 5px 10px;
  border-radius: 4px;
  //font-family: MyWebFont;
  border: 1px solid black;
  font-size: 14px;
  margin: 5px 5px 1px 0px;
  height: 28px;
  background-color: white !important;
  max-width: 200px;
  min-width: 100px;
  display: inline-block;
}
.classtagCk {
  margin: 4px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid gainsboro;
  overflow: auto;
  float: left;
  min-width: 100px;
  width: auto;
  overflow: hidden;
  color: #494848;
  font-size: 14px;
  padding: 5px;
}
.options {
  margin: 8px 40px;
  overflow: hidden;

  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  gap: 50px;
}
.textbox {
  height: 35px;
  margin-top: 8px;
  width: 260px;
}
.speakertag {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: 25px;
  padding: 5px;
  background: whitesmoke;
}

.speakername {
  font-size: 14px;
  font-weight: 400;
}
.cancelicon {
  width: 20px;
  height: 20px;
}
.add {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.speakerlists{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
.speakertag{
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: 20px;
  padding: 2px;
  background: whitesmoke;
}
.speakername{
  font-size: 10px;
  font-weight: 400;
}
.cancelicon{
  width: 20px;
  height: 20px;
}
