.mainpopup {
  width: 40%;
  height: 70vh;
  background: white;
  border-radius: 5px;
  padding: 20px;
  overflow: auto;
}

.heading {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.submit {
  background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.submit:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.cancel {
  background: transparent;
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: var(--color-button-background);
  font-weight: 300px;
  border-radius: 5px;
  border: 1px solid var(--color-button-background);
  margin: 0px;
}

.cancel:hover {
  transform: scale(1.05);
  cursor: pointer;
}
