.main{
    height: 70%;
    width: 70%;
    padding: 20px;
    border-radius: 5px;
    background: white;
}

.topbar{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.closeicon:hover{
 cursor: pointer;
 transform: scale(1.05);
}

.firstblock{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.leftblock{
    width: 20%;
}

.rightblock{
    width: 80%;
    text-align: left;
}

.speakername{
    font-size: 20px;
    font-weight: bold;
}

.speakerdesig{
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    overflow-y: scroll;
}

.bottombar{
    width: 100%;
    height: 40%;
    overflow-y: auto;
}