.mainpopup {
  background: white;
  width: 30%;
  height: fit-content;
  text-align: center;
  border-radius: 5px;
  padding: 20px;
}

.heading {
  font-size: 16px;
  text-align: left;
  margin: 0px;
  padding: 10px;
  font-weight: bold;
}

.closeholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.closebutton:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.forminput {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.inputbox {
  width: 50%;
  font-size: 16px;
}

.savebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 20px 0px;
}

.save {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
}

.save:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.cancel {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-button-background);
  padding: 5px 20px;
  border: 1px solid var(--color-button-background);
  border-radius: 5px;
  background: transparent;
}

.cancel:hover {
  cursor: pointer;
  transform: scale(1.04);
}
.radiolable {
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  padding-bottom: 20px;
  text-align: center;
}
.inputblock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 90%;
  padding: 5px 0px;
}

.labelholder {
  width: 25%;
  text-align: right;
}

.inputholder {
  width: 100%;
  text-align: left;
}

.label {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}

.inputfield {
  width: 100%;
  padding: 5px;
  height: 25px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 12px;
}
