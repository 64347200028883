.main {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 25px;
    overflow-y: auto;
  }

.financemain{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 20px;
}

.sessionlabel{
    font-size: 14px;
    font-weight: 400;;
}

.sessionSelect{
    width: 300px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 5px;
}
.exp_btn {
    display: flex;
    align-items: center;
    color: #3f51b5;
    text-decoration: none;
  }