.main {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 25px;
  overflow-y: auto;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 20px;
}
.coursecardaddress {
  display: flex;
  flex-direction: column;

  padding-left: 20px;
}
.infoPaper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.coursecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  justify-content: center;
  padding: 1rem 1rem;
  gap: 0rem;
}
.username {
  font-weight: 600;
  font-size: 16px !important;
  margin-bottom: 0;
}
.useremail {
  color: #777777;
  font-weight: 400;
  font-size: 16px !important;
}
.pipeline {
  height: 120px;
  border-right: 2px solid #01498e;
  margin: 0 2rem;
}

.tabsholder{
  padding-top: 30px;
}

.head{
  font-size: 14px;
  font-weight: 400;
}

.objImg {
  margin-top: 20px;
}
