.popupcontainer {
  margin: auto;
  height: fit-content;
  width: 400px;
  padding: 30px;
  background: white;
  border-radius: 5px;
}

.mainpopup {
  background: white;
  width: 30%;
  height: fit-content;
  text-align: center;
  border-radius: 5px;
  padding: 20px;
}

.topbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.closeicon:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.header {
  font-size: 16px;
  text-align: left;
  margin: 0px;
  padding: 10px;
  font-weight: bold;
}

.radioholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  padding: 10px 0px;
}

.radiolable {
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  padding-bottom: 20px;
  text-align: center;
}

.input {
  height: 10px;
  width: 10px;
}

.inputlabel {
  font-size: 16px;
  font-weight: 400;
}

.inputbox {
  width: 100%;
  border: 1px solid #6a6a6a;
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px 0px;
}

.submit {
  background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.submit:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.cancel {
  background: transparent;
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: var(--color-button-background);
  font-weight: 300px;
  border-radius: 5px;
  border: 1px solid var(--color-button-background);
  margin: 0px;
}

.cancel:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.caution {
  font-size: 12px;
  font-weight: bold;
  padding-top: 10px;
  color: red;
}

.inputblock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 90%;
  padding: 5px 0px;
}

.labelholder {
  width: 31%;
  text-align: right;
}

.inputholder {
  width: 100%;
  text-align: left;
}

.label {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}

.inputfield {
  width: 100%;
  padding: 5px;
  height: 25px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 12px;
}
.textarea {
  width: 100%;
  padding: 5px;
  resize: none;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 12px;
  font-family: "Oxygen", sans-serif;
}
