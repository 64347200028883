.main {
  margin: 0px;
  padding: 25px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
}

.tenantselect {
  display: flex;
  align-items: center;
  display: flex-start;
  gap: 15px;
  margin: 0px;
}

.selecttenant {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

.tenantname {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.tenantname:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.statsholder{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding-top: 20px;
}

.total{
  width: 30%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  text-align: center;
  background: white;
  height: 250px;
  padding: 20px;
}

.top{
  width: 60%;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  height: 250px;
  padding: 20px;
}

.objImg {
  margin-top: 20px;
}