.mainpopup{
    width: fit-content;
    height: fit-content;
    background: white;
    border-radius: 5px;
    border: none;
    padding: 20px;
}

.header{
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 20px;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.buttonyes{
  background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.buttonno{
  background: white;
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: var(--color-button-background);
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid var(--color-button-background);
  margin: 0px;
}

.buttonyes:hover{
    cursor: pointer;
  transform: scale(1.05);
}

.buttonno:hover{
    cursor: pointer;
  transform: scale(1.05);
}