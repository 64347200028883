.overall-container {
  width: 795px;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 30px 0; */
  overflow-y: hidden;
}

.container {
  width: 100%;
  min-height: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #777777;
  padding: 8% 0;
  min-height: max-content;

  overflow-y: hidden;

  background-color: #ffffff;
}
.container .img-c {
  display: flex;
  justify-content: center;
}
.container .org-container {
  margin: 25px 0;
  line-height: 1;
}

.container .name {
  margin: 0 0 15px 0;
}
.container .complete {
  margin: 0 0 15px 0;
}
.title-container {
  margin: 0 0 15px 0;
  line-height: 1;
}
.container .org-container .org {
  color: #ff6f4d;
  font-size: 35px;
  font-weight: 900;
}
.date {
  margin: 13px 0;
}

.clearfix {
  max-width: 750px;
}

.title-container .truncate {
  display: -webkit-box;
  max-width: 650px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.addbutton {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}
