.main {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 25px;
    overflow-y: auto;
  }
  
  .heading {
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
  }

  .addholder{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 20px;
  }

  .add {
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    background: var(--color-button-background);
  }
  
  .add:hover {
    cursor: pointer;
    transform: scale(1.04);
  }

  .calender{
      width: 100%;
      height: fit-content;
  }