.maindiv {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading{
  font-size: 16px;
  font-weight: bold;
}

.maincontainer {
  width: 100%;
  height: fit-content;
  border-radius: 2px;
  background: var(--popup--background);
  justify-content: center;
  margin: 0px;
  padding: 20px;
}
#container_1 {
  border-collapse: separate;
  border-spacing: 10px 15px;
  td {
    min-width: 100px;
    text-align: right;
  }
  label::after {
    content: "*";
    color: red;
  }
  input {
    min-width: 150px;
    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }
  textarea {
    width: 250px;
  }
}
#container_2 {
  border-collapse: separate;
  border-spacing: 10px 10px;
  width: 100%;
  td {
    width: 160px;
    text-align: right;
  }
  label::after {
    content: "*";
    color: red;
  }
  input {
    width: 250px;
    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }
}
.iconcontainer {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

.create {
  font-size: 20px;
  font-family: "nunito";
  color: #000 !important;
  font-weight: 400;
}

.form {
  padding: 20px;
  text-align: center;
  margin: 10px auto;
}

.field {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin: auto;
  gap: 20px;
  width: 500px;
  padding-right: 70px;
  label::after {
    content: "*";
    color: red;
  }
}
.closeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.submit {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 70px;
  color: var(--button-text);
  font-weight: 500;
  font-size: 18px;
}

.submit:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.closebutton:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.fileinput {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 40px 0px;
  border: 1px dashed #6a6a6a;
}
.browse:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.savebutton {
  background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.closebutton {
  background: transparent;
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: var(--color-button-background);
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid var(--color-button-background);
  margin: 0px;
}

.savebutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.closebutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.closeicon:hover{
  cursor: pointer;
  transform: scale(1.2);
}

.hoverbox:hover{
  cursor: pointer;
  transform: scale(1.05);
}
