.maindiv {
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
  
    justify-content: center;
  }
  
  .maincontainer {
    width: 40%;
    height: fit-content;
    border-radius: 5px;
    background: white;
    justify-content: center;
    margin: auto;
    padding: 20px;
  }

.heading{
    font-size: 16px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.form {
    text-align: center;
    margin: 10px auto;
  }

.topbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

  .field {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: auto;
    gap: 20px;
  }

  .close:hover{
    cursor: pointer;
    transform: scale(1.05);
  }

  .buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .submit{
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    background: var(--color-button-background);
  }

  .submit:hover {
    cursor: pointer;
    transform: scale(1.05);
  }


  .cancel{
    font-size: 14px;
    font-weight: bold;
    color: var(--color-button-background);
    padding: 5px 20px;
    border: 1px solid var(--color-button-background);
    border-radius: 5px;
    background: white; 
  }

  .cancel:hover {
    cursor: pointer;
    transform: scale(1.05);
  }