.feedback{
    display: flex;
    align-items: center;
   gap: 20px; 
}
.feedback-heading{
    width: 150px;
}
.star-icon{
    width: 100%;
}

.feedback-item-container{
    
}

.feedback-table{
    border: 1px solid #000;
    border-collapse: collapse;
}

.feedback-table td, th {
    border: 1px solid #000;
    text-align: center;
}

.feedback-table  th {
  padding: .5rem 1rem;
}