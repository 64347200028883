
  .feedbackmaincontainer {
    height: 90vh;
    width: 100%;
    padding: 20px;
    background: white;
    border: none;
    border-radius: 5px;
    overflow-y: scroll;
  }
  
  
  .maincontainernew {
    height: 60%;
    width: 80%;
    padding: 20px;
    background: white;
    border: none;
    border-radius: 5px;
    overflow-y: scroll;
  }
  
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header {
    font-size: 16px;
    font-weight: 400;
  }
  
  .closeicon {
    cursor: pointer;
    transform: scale(1.05);
  }
  
  .upload {
    background: var(--color-button-background);
    padding: 5px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    margin: 0px;
  }
  
  .upload:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  
  .view {
    background: white;
    padding: 5px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: var(--color-button-background);
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid var(--color-button-background);
    margin: 0px;
  }
  
  .view:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  .attendence {
  }
  .attendencediv {
    display: flex;
    justify-content: end;
    padding: 10px;
  }
  
  .feedbackbutton{
    background: var(--color-button-background);
    padding: 10px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: white;
    font-weight: 300px;
    border-radius: 5px;
    border: none;
    margin: 0px;
  }
  
  .feedbackbutton:hover{
    cursor: pointer;
    transform: scale(1.05);
  }
  
  
  .buttonsholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  
  