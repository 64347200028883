.main {
  height: 100%;
  margin: 0px;
  padding: 25px;
  width: 100%;
  overflow-y: auto;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 20px 0px;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}

.add {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
}

.add:hover {
  cursor: pointer;
  transform: scale(1.04);
}
