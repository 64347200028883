.paper {
  height: 200px;
  min-width: 340px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.header {
  height: 40px;
  background-color: gray;
  color: white;
  font-size: 16px;
  //padding: 10px 50px 0px 110px;
  display: flex;
  gap: 104px;
  justify-content: center;
  padding-top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.count {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 0 0 0;
  margin: 0px 78px;
  font-size: 30px;
  /*  &::after {
    content: "";
    position: absolute;
    top: 66px;
    right: 77%;
    height: 58%;
    width: 0.5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: rgba(0, 0, 0, 0.6);
    transform: scaleY(1.3);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
      background-color 0.1s;
  } */
}
.leftsection {
  font-family: "nunito";
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  height: 50px;
}
.iconholder {
  height: 20px;
  width: 20px;
}
.pipeline {
  height: 160px;
  border-right: 2px solid #01498e;
  margin: 0 2rem;
}
.leftside {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.buttonadd {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
}

.buttonadd:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.head{
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: black;
}
