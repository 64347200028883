.main {
    margin: 0px;
    padding: 25px;
    height: 100%;
    width: 100%;
    overflow-y: auto; 
  }
  .heading {
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
  }
  .tenantselect {
    display: flex;
    align-items: center;
    display: flex-start;
    gap: 15px;
    margin: 0px;
  }
  .selecttenant {
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
  }
  .tenantname {
    background: var(--color-button-background);
    padding: 5px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 16px;
    color: white;
    font-weight: 300px;
    border-radius: 5px;
    border: none;
    margin: 0px;
  }
  .tenantname:hover {
    cursor: pointer;
    transform: scale(1.04);
  }
  
  
  
  
  
  
  