.paper {
  height: 200px;
  min-width: 340px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  display: flex;
}

.header {
  height: 40px;
  color: black;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
  font-size: 30px;
}

.gridholder {
  height: 100%;
}

.graphholder {
  display: block;
  height: fit-content;
  margin: 0px;
}
