.main {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 25px;
  overflow-y: auto;
}

.buttonholder{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.addbutton{
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
}

.addbutton:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.head{
  font-size: 14px;
  font-weight: 400;
}