@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

.App {
  background-color: #ededed;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Oxygen", sans-serif;
}

:root {
  --color-primary: #d2d2d2;
  --color-primary-orange: #f36045;
  --color-primary-blue: #093055;
  --color-button-background: #f36045;

  --color-grey-light-1: #black;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);
}

.greenborder {
  border: 2px solid green;
  border-radius: 5px;
}
.redboreder {
  border: 2px solid red;
  border-radius: 5px;
}
.normalborder {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.highlightborder {
  border: 1px solid rgb(4, 20, 233);
  border-radius: 5px;
}
