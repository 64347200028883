.main {
    height: 100%;
    margin: 0px;
    padding: 25px;
    overflow-y: auto;
    width: 100%;
  }
  
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 20px 0px;
  }

  .leftside{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
  }
  
  .heading {
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
  }

  .buttonadd{
    font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
  }

  .buttonadd:hover{
      transform: scale(1.05);
      cursor: pointer;
  }