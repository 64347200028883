.main {
  height: 100%;
  width: 100%;
  margin: 0px;
  overflow-y: auto;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 20px;
}

.bars {
  padding: 3px 10px 5px 10px;
  border-radius: 4px;

  border: 1px solid gray;
  font-size: 14px;
  margin: 1px 5px 1px 0px;
  height: auto;
  background-color: rgb(242, 242, 242) !important;
  max-width: 200px;
  min-width: 100px;
}
.addbuttongreen {
  background: rgb(67, 161, 67);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.addbutton {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  font-weight: bold;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.exp_btn {
  display: flex;
  align-items: center;
  color: #3f51b5;
  text-decoration: none;
}
