.main {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 25px;
  overflow-y: auto;
}

.subheading {
  font-size: 24px;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 10px;
}

.tenantselect {
  display: flex;
  align-items: center;
  display: flex-start;
  gap: 15px;
  margin: 0px;
  padding-bottom: 20px;
}

.selecttenant {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

.tenantname {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.tenantname:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.statsholder {
  display: flex;
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  
}

.totalcount {
  // width: 48%;
  flex-grow: 1;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.todaycount {
  // width: 48%;
  flex-grow: 1;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  // margin-left: 170px;
}

.cardholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.count {
  font-size: 16px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
}

.type {
  font-size: 16px;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}

.select {
  font-size: 14px;
  width: 240px;
}

.gridholder {
  height: 100%;
}

.graphholder {
  display: block;
  height: 40vh;
  margin: 0px;
  padding-top: 50px;
}
