.maindiv {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  overflow: auto;
  justify-content: center;
}

.maincontainer {
  width: 50%;
  height: fit-content;
  border-radius: 5px;
  background: white;
  justify-content: center;
  margin: auto;
  padding: 20px;
}

.iconcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create {
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.form {
  padding: 20px;
  text-align: center;
  margin: 10px auto;
}

.field {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin: auto;
  gap: 20px;
}

.buttonholder{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.submit {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: var(--color-button-background);
}

.cancel {
  font-size: 14px;
  font-weight: bold;
  color:  var(--color-button-background);
  padding: 5px 20px;
  border: 1px solid  var(--color-button-background);
  border-radius: 5px;
  background: white;
}

.cancel:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.submit:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.closebutton:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.btn_color {
  background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.btn_colordis {
  background-color: grey;
  color: white;
  cursor: not-allowed;
  font-family: "nunito";
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
  border: none;
  height: 30px;
  width: fit-content;
}

.btn_color:hover {
  cursor: pointer;
  transform: scale(1.1);
}
