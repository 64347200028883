.main{
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0px;
}

.dataholder{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.leftdiv{
    display: flex;
    padding: 20px;
    align-items: flex-start;
    gap: 50px;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
}

.leftside{
    flex-grow:  1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.rightside{
    flex-grow:  1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.image{
    width: 300px;
    height: 150px;
    border-radius: 5px;
}

.fields{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.bigfields{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.lowersection{
    padding: 20px;
    margin-top: 20px;
}

.holder{
    padding: 10px 0px;
}

.lefthead{
    font-weight: 400;
    font-size: 14px;

}

.lefthead+span{
    display: flex;
    align-items: center;
}

.righttext{
    font-size: 14px;
    font-weight: 600;
}

.speakers{
    font-size: 14px;
    text-decoration: underline;
    color: #275780;
}

.speakers:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.tableStyle {
    th {
      background-color: #275780;
      color: #fff;
    }
    td,
    th {
      border: 1px solid black;
      border-collapse: collapse;
      padding: 5px;
    }
    border: 1px solid black;
    border-collapse: collapse;
  }


