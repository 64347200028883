.main {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 25px;
  overflow-y: auto;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 20px;
}
