.mainpopup {
    background: white;
    width: 30%;
    height: fit-content;
    text-align: center;
    border-radius: 5px;
    padding: 20px;
  }
  
  .heading {
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
    padding-bottom: 20px;
  }

  .forminput {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
  
  .inputbox {
    width: 50%;
    font-size: 16px;
  }
  
  .savebutton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 20px 0px;
  }
  
  .save {
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    background: var(--color-button-background);
  }
  
  .save:hover {
    cursor: pointer;
    transform: scale(1.04);
  }
  
  .cancel {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-button-background);
    padding: 5px 20px;
    border: 1px solid var(--color-button-background);
    border-radius: 5px;
    background: transparent;
  }
  
  .cancel:hover {
    cursor: pointer;
    transform: scale(1.04);
  }