.dashcls1active{
    stroke: none;
    fill: none;
}

.dashcls2active{
    stroke: var(--color-primary-blue); 
    fill: none;
    stroke-width: 3px;
}

.dashcls3active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue); 
}

.dashcls4active{
    stroke: var(--color-primary-blue);
    fill: none;
}

.dashcls1{
    stroke: none;
    fill: none;
}

.dashcls2{
    stroke: var(--color-primary-orange); 
    fill: none;
    stroke-width: 3px;
}

.dashcls3{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange); 
}

.dashcls4{
    stroke: var(--color-primary-orange);
    fill: none;
}

.tencls1active{
    stroke: var(--color-primary-blue);
    fill:var(--color-primary-blue);
}

.tencls2active{
    stroke: var(--color-primary-blue);
    fill: none;
    stroke-width: 3px;
}

.tencls3active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.tencls4active{
    stroke: none;
    fill: var(--color-primary-blue);
}

.tencls1{
    stroke: var(--color-primary-orange);
    fill:var(--color-primary-orange);
}

.tencls2{
    stroke: var(--color-primary-orange);
    fill: none;
    stroke-width: 3px;
}

.tencls3{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

.tencls4{
    stroke: none;
    fill: var(--color-primary-orange);
}

.concls1active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.concls2active{
    stroke: var(--color-primary-blue);
    fill: none;
    stroke-width: 3px;
}

.concls3active{
    stroke: var(--color-primary-blue);
    fill: white;
}

.concls4active{
    stroke: var(--color-primary-blue);
    fill: white;
}

.concls1{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

.concls2{
    stroke: var(--color-primary-orange);
    fill: none;
    stroke-width: 3px;
}

.concls3{
    stroke: var(--color-primary-orange);
    fill: white;
}

.concls4{
    stroke: var(--color-primary-orange);
    fill: white;
}

.encls1active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.encls2active{
    stroke: var(--color-primary-blue);
    fill: none;
    stroke-width: 3px;
}

.encls3active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.encls4active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.encls1{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

.encls2{
    stroke: var(--color-primary-orange);
    fill: none;
    stroke-width: 3px;
}

.encls3{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

.encls4{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

.evcls1active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.evcls2active{
    stroke: var(--color-primary-blue);
    fill: none;
    stroke-width: 3px;
}

.evcls3active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.evcls4active{
    stroke: var(--color-primary-blue);
    fill: var(--color-primary-blue);
}

.evcls1{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

.evcls2{
    stroke: var(--color-primary-orange);
    fill: none;
    stroke-width: 3px;
}

.evcls3{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

.evcls4{
    stroke: var(--color-primary-orange);
    fill: var(--color-primary-orange);
}

