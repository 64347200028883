.PhoneInput {
  height: 35px;
  border-bottom: 0.2em solid #0288d1;
  font-size: 0.8rem;
  padding: 4px 0 5px;
  line-height: 1.4375em;
}
.PhoneInputError {
  height: 35px;
  border-bottom: 0.2em solid red;
  font-size: 0.8rem;
  padding: 4px 0 5px;
  line-height: 1.4375em;
}

.PhoneInputInput {
  outline: none;
  border: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  padding-left: 4px;
}
.PhoneInputInput::placeholder {
  color: #777;
}
.PhoneInputInput:focus .PhoneInput {
  height: 40px;
  border-bottom: 1px solid #0288d1;

  font-size: 0.8rem;
}
.PhoneInputCountrySelectArrow-color {
  background-color: green;
}
