.main{
    padding: 20px;
    height: fit-content;
    width: 40%;
    background: white;
    border: none;
    border-radius: 5px;
}

.heading{
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: center;
}

.area{
    width: 100%;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
    resize: none;
    font-family: sans-serif;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 30px 0px;
}

.submit{
    background: var(--color-button-background);
      padding: 10px 20px;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      color: white;
      font-weight: 300px;
      border-radius: 5px;
      border: none;
      margin: 0px;
    }
    
    .cancel{
    background: transparent;
      padding: 10px 20px;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      color: var(--color-button-background);
      font-weight: 300px;
      border-radius: 5px;
      border: 1px solid var(--color-button-background);
      margin: 0px;
    }
    
    .submit:hover{
        cursor: pointer;
      transform: scale(1.05);
    }
    
    .cancel:hover{
        cursor: pointer;
      transform: scale(1.05);
    }