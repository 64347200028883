.paper1 {
  height: 250px;
  min-width: 340px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.paper2 {
  min-height: 250px;
  min-width: 500px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.header {
  height: 40px;
  background-color: gray;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.count {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 53px 0 0 0;
  margin: 0px 78px;
  font-size: 30px;
  /*  &::after {
      content: "";
      position: absolute;
      top: 66px;
      right: 77%;
      height: 58%;
      width: 0.5px;
      background-color: rgba(0, 0, 0, 0.6);
      color: rgba(0, 0, 0, 0.6);
      transform: scaleY(1.3);
      transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
        background-color 0.1s;
    } */
}
.leftsection {
  font-family: "nunito";
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  height: 50px;
}
.iconholder {
  height: 20px;
  width: 20px;
}
.main {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 25px;
  overflow-y: auto;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.addbutton {
  background: var(--color-button-background);
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  color: white;
  font-weight: 300px;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.addbutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}
