.maincontainer {
  width: 800px;
  height: 500px;
  padding: 20px;
  background-color: #fff;
}

.savebutton {
  background: var(--color-button-background);
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0px;
}

.savebutton:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.closeicon:hover {
  cursor: pointer;
  transform: scale(1.05);
}
