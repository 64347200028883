.objectPlayerOname {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
}
.closeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeicon:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.heading {
  font-size: 16px;
  text-align: left;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}
